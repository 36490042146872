.app {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.app-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    font-size: 24px;
    margin-top: 0;
  }

  @media screen and (max-width: 300px) {
    h1, p {
      word-spacing: 100vw;
    }
  }
}


.banner {
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 50%;
  flex: 1;
}

.links {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  min-width: 200px;
  height: 50px;
  overflow: visible;
  background-color: #fff;

  @media screen and (max-width: 300px) {
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.links svg {
  height: 50px;
  width: auto;
  display: flex;
}
